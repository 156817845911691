import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [menu, setMenu] = React.useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  });

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <div id="navbar" className="navbar-area sticky-top">
      <div className="main-nav">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/" onClick={toggleNavbar} className="navbar-brand">
              <img
                src="/images/logo-purple.png"
                alt="logo"
                className="nk__ac_logo"
              />
              <img
                src="/images/logo-2.png"
                alt="logo2"
                className="nk__sticky_logo"
              />
            </Link>

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav justify-content-end">
                <li className="nav-item">
                  <Link
                    to="/about-us"
                    onClick={toggleNavbar}
                    className="nav-link"
                  >
                    About us
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/health-packages"
                    onClick={toggleNavbar}
                    className="nav-link"
                  >
                    Health Packages
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/facilities"
                    onClick={toggleNavbar}
                    className="nav-link"
                  >
                    Facilities
                  </Link>
                  {/* <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to="/" onClick={toggleNavbar} className="nav-link" >
                        
                          Radiology
                        
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" onClick={toggleNavbar} className="nav-link" >
                        
                          Laboratories
                        
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" onClick={toggleNavbar} className="nav-link" >
                        
                          Cardiology
                        
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" onClick={toggleNavbar} className="nav-link" >
                        
                          Pulmonology
                        
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" onClick={toggleNavbar} className="nav-link" >
                        
                          Neurology
                        
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" onClick={toggleNavbar} className="nav-link" >
                        
                          Fetal Medicine
                        
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" onClick={toggleNavbar} className="nav-link" >
                        
                          Molecular Diagnostics & Genetics
                        
                      </Link>
                    </li>
                  </ul> */}
                </li>

                <li className="nav-item">
                  <Link
                    to="/quality-assurance"
                    onClick={toggleNavbar}
                    className="nav-link"
                  >
                    Quality Assurance
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/empanelment"
                    onClick={toggleNavbar}
                    className="nav-link"
                  >
                    Empanelment
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/gallery"
                    onClick={toggleNavbar}
                    className="nav-link"
                  >
                    Gallery
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/contact"
                    onClick={toggleNavbar}
                    className="nav-link"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
