import React from "react";
import PartnerSider from "./PartnerSider";
import { Waypoint } from "react-waypoint";
import CountUp from "react-countup";

const Stats = () => {
  const [waypointValue, setWaypointValue] = React.useState(false);

  const handleWaypointEnter = function () {
    setWaypointValue(!waypointValue);
  };

  return (
    <React.Fragment>
      <PartnerSider />
      <Waypoint onEnter={handleWaypointEnter} />
      <div className="counter-area ptb-70">
        <div className="container">
          <div className="row counter-bg">
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="counter-item">
                <i className="icofont-microscope-alt"></i>
                {waypointValue ? (
                  <h3 className="counter">
                    <CountUp start={0} end={1000} duration={3} />
                  </h3>
                ) : (
                  <h3 className="counter">1000</h3>
                )}
                <p>Tests Available</p>
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="counter-item">
                <i className="icofont-people"></i>
                {waypointValue ? (
                  <h3>
                    <span className="counter">
                      <CountUp start={0} end={400000} duration={3} />
                    </span>
                  </h3>
                ) : (
                  <h3>
                    <span className="counter">400000</span>
                  </h3>
                )}
                <p>Happy Patients</p>
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="counter-item">
                <i className="icofont-doctor-alt"></i>
                {waypointValue ? (
                  <h3>
                    <span className="counter">
                      <CountUp start={0} end={15} duration={3} />
                    </span>
                  </h3>
                ) : (
                  <h3>
                    <span className="counter">15</span>
                  </h3>
                )}
                <p>Doctors</p>
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 border-0">
              <div className="counter-item">
                <i className="icofont-badge"></i>
                {waypointValue ? (
                  <h3>
                    <span className="counter">
                      <CountUp start={0} end={35} duration={3} />
                    </span>
                  </h3>
                ) : (
                  <h3>
                    <span className="counter">35</span>
                  </h3>
                )}
                <p>Years Experience</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Stats;
