import React from "react";
import { Routes, Route } from "react-router-dom";
import GoTop from "./components/_App/GoTop";
import TopHeader from "./components/_App/TopHeader";
import NavBar from "./components/_App/NavBar";
import Footer from "./components/_App/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import HealthPackagesPage from "./pages/HealthPackagesPage";
import HealthPackagesCategoryPage from "./pages/HealthPackagesCategoryPage";
import FacilitiesPage from "./pages/FacilitiesPage";
import RadiologyPage from "./pages/RadiologyPage";
import LaboratoriesPage from "./pages/LaboratoriesPage";
import FetalMedicinePage from "./pages/FetalMedicinePage";
import LiverFibroScanPage from "./pages/LiverFibroScanPage";
import CardiologyPage from "./pages/CardiologyPage";
import MolecularDiagnosticsPage from "./pages/MolecularDiagnosticsPage";
import NeurologyPage from "./pages/NeurologyPage";
import PulmonologyPage from "./pages/PulmonologyPage";
import QualityAssurancePage from "./pages/QualityAssurancePage";
import EmpanelmentPage from "./pages/EmpanelmentPage";
import GalleryPage from "./pages/GalleryPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundPage";
import AnanyaDodaSarinDetailsPage from "./pages/AnanyaDodaSarinDetailsPage";
import DoctorDodaDetailsPage from "./pages/DoctorDodaDetailsPage";
import RuhaniDodaKheraDetailsPage from "./pages/RuhaniDodaKheraDetailsPage";
import VeenaDodaDetailsPage from "./pages/VeenaDodaDetailsPage";
import PaymentResponsePage from "./pages/PaymentResponsePage";

function App() {
  return (
    <div className="App">
      <GoTop />
      <TopHeader />
      <NavBar />
      <section className="app_main">
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/health-packages" element={<HealthPackagesPage />} />
          <Route
            path="/health-packages-category"
            element={<HealthPackagesCategoryPage />}
          />
          <Route path="/facilities" element={<FacilitiesPage />} />
          <Route path="/radiology" element={<RadiologyPage />} />
          <Route path="/laboratories" element={<LaboratoriesPage />} />
          <Route path="/fetal-medicine" element={<FetalMedicinePage />} />
          <Route path="/liver-fibroscan" element={<LiverFibroScanPage />} />
          <Route path="/cardiology" element={<CardiologyPage />} />
          <Route
            path="/molecular-diagnostics"
            element={<MolecularDiagnosticsPage />}
          />
          <Route path="/neurology" element={<NeurologyPage />} />
          <Route path="/pulmonology" element={<PulmonologyPage />} />
          <Route path="/quality-assurance" element={<QualityAssurancePage />} />
          <Route path="/empanelment" element={<EmpanelmentPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/doctor-ananya-doda-sarin-details"
            element={<AnanyaDodaSarinDetailsPage />}
          />
          <Route
            path="/doctor-doda-details"
            element={<DoctorDodaDetailsPage />}
          />
          <Route
            path="/doctor-ruhani-doda-khera-details"
            element={<RuhaniDodaKheraDetailsPage />}
          />
          <Route
            path="/doctor-veena-doda-details"
            element={<VeenaDodaDetailsPage />}
          />
          <Route path="/payment-response" element={<PaymentResponsePage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </section>
      <Footer />
    </div>
  );
}

export default App;
