import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PaymentResponsePage = () => {
  const [state, setState] = useState({
    orderId: "",
    trackingId: "",
    amount: "",
    orderStatus: "",
  });

  const apiRequest = async (id) => {
    try {
      const response = await fetch(
        "https://www.server-host.dodahealthcare.com/ccavRequestHandler",
        // "http://localhost:3001/ccavRequestHandler",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({ order_id: state.orderId }),
        }
      );
      const data = await response.json(state);

      setState((prev) => {
        return {
          ...prev,
          accessCode: data.accessCode,
          encRequest: data.encRequest,
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // console.log(urlParams);
    const orderId = urlParams.get("orderId");
    const trackingId = urlParams.get("trackingId");
    const amount = urlParams.get("amount");
    const orderStatus = urlParams.get("orderStatus");
    // console.log(orderId);

    setState((prev) => {
      return {
        ...prev,
        orderId: orderId,
        trackingId: trackingId,
        amount: amount,
        orderStatus: orderStatus,
      };
    });

    apiRequest(orderId);

    console.log(orderId, trackingId);

    const statusRequest = async () => {
      try {
        const statusResponse = await fetch(
          // `http://localhost:3001/ccavStatusHandler/${orderId}/${trackingId}`,
          `https://www.server-host.dodahealthcare.com/ccavStatusHandler/${orderId}/${trackingId}`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        const statusData = await statusResponse.json();
        console.log(JSON.stringify(statusData));
      } catch (err) {
        console.log(err);
      }
    };
    statusRequest();
  }, []);

  return (
    <React.Fragment>
      <div className="error-area">
        <div className="error-item">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="error-text">
                <p>
                  Payment of Rs.{state.amount} with order id{" "}
                  <b>{state.orderId}</b> {state.orderStatus}
                </p>
                <h2>Refrence id for the order {state.trackingId}</h2>
                <Link to="/">Return to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentResponsePage;
