import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const OurDoctors = () => {
  return (
    <div className="doctors-area ptb-70">
      <div className="container">
        <div className="section-title">
          <ScrollAnimation animateIn="fadeInUp" offset="100">
            <h2>Meet Our Founders</h2>
          </ScrollAnimation>
        </div>
        <div className="row align-items-center mb-50">
          <div className="col-md-3 nk__text-center">
            <Link to="/doctor-doda-details">
              <img src="/images/dr-ss-doda.png" alt="Doctor" />
            </Link>
          </div>
          <div className="col-md-9 nk__text-left">
            <ScrollAnimation animateIn="fadeInRight" offset="100">
              <h3>
                <Link to="/doctor-doda-details">DR. S. S. DODA</Link>
              </h3>
            </ScrollAnimation>
            <p>FOUNDER DIRECTOR & CHIEF RADIOLOGIST</p>
            <p className="nk__overview_info">
              Dr. S. S. Doda, an Eminent Radiologist did his MD in
              Radio-Diagnosis in 1981 from S. P. Medical College, Bikaner,
              Rajasthan. Following Senior Residency at Dr. RML Hospital, New
              Delhi, he started his career with the first 3rd generation whole
              body CT scan set up of the country at Sir Ganga Ram Hospital, New
              Delhi in 1983, a centre where patients from whole of North,
              Central, East and North East India were referred for CT Scan. Dr
              Doda a pioneer in the field of body CT ,delivered over 200 guest
              lectures on CT at national and regional conferences of various
              branches of medicine, addressed CME’s in different cities and
              towns of India, medical colleges and state IMA meetings, etc.
              providing continuing medical education to radiologists, physicians
              and surgeons...
            </p>
            <Link to="/doctor-doda-details" className="explore_more">
              Explore More <i className="icofont-rounded-double-right"></i>
            </Link>
          </div>
        </div>
        <div className="row align-items-center mb-50 flex-row-reverse">
          <div className="col-md-3 nk__text-center">
            <Link to="/doctor-veena-doda-details">
              <img src="/images/dr-veena-doda.png" alt="Doctor" />
            </Link>
          </div>
          <div className="col-md-9 nk__text-right">
            <ScrollAnimation animateIn="fadeInLeft" offset="100">
              <h3>
                <Link to="/doctor-veena-doda-details">DR. VEENA DODA</Link>
              </h3>
            </ScrollAnimation>
            <p>MEDICAL DIRECTOR</p>
            <p className="nk__overview_info">
              Dr. Veena Doda graduated from Lady Hardinge Medical College and
              Hospital, New Delhi, in 1976. She did her residency from Dr. Ram
              Manohar Lohia Hospital and continued her career in the same
              hospital. After 34 years of her succesful tenure in providing
              strategic operations and leadership in various capacities as Head
              of Department of Transfusion Medicine and Additional Medical
              Superintendant, Dr Ram Manohar Lohia Hospital, she has taken over
              as Director, Pathology Lab at Dr Dodas Diagnostics and Healthcare
              from July 2015 onwards...
            </p>
            <Link to="/doctor-veena-doda-details" className="explore_more">
              Explore More <i className="icofont-rounded-double-right"></i>
            </Link>
          </div>
        </div>
        <div className="row align-items-center mb-50">
          <div className="col-md-3 nk__text-center">
            <Link to="/doctor-ruhani-doda-khera-details">
              <img src="/images/dr-ruhani-doda.png" alt="Doctor" />
            </Link>
          </div>
          <div className="col-md-9 nk__text-left">
            <ScrollAnimation animateIn="fadeInRight" offset="100">
              <h3>
                <Link to="/doctor-ruhani-doda-khera-details">
                  DR. RUHANI DODA KHERA
                </Link>
              </h3>
            </ScrollAnimation>
            <p>ASSOCIATE DIRECTOR & CONSULTANT RADIOLOGIST</p>
            <p className="nk__overview_info">
              A Medical graduate from Chaudhary Charan Singh University, Meerut,
              India / S.M. College followed by Post Graduate Residency Training
              (Radio-Diagnosis), National Board of Examinations from Sir Ganga
              Ram Hospital, in 2012. Her major work has been Comparative
              Evaluation of Clinical Examination, Mammography, High Resolution
              Ultrasonography and 3T MRI in Breast Lesions...
            </p>
            <Link
              to="/doctor-ruhani-doda-khera-details"
              className="explore_more"
            >
              Explore More <i className="icofont-rounded-double-right"></i>
            </Link>
          </div>
        </div>

        <div className="row align-items-center mb-50 flex-row-reverse">
          <div className="col-md-3 nk__text-center">
            <Link to="/doctor-ananya-doda-sarin-details">
              <img src="/images/dr-ananya-doda.png" alt="Doctor" />
            </Link>
          </div>
          <div className="col-md-9 nk__text-right">
            <ScrollAnimation animateIn="fadeInLeft" offset="100">
              <h3>
                <Link to="/doctor-ananya-doda-sarin-details">
                  DR. ANANYA DODA SARIN
                </Link>
              </h3>
            </ScrollAnimation>
            <p>ASSOCIATE DIRECTOR & HEAD OF LABS</p>
            <p className="nk__overview_info">
              Dr. Ananya Doda Sarin is a Consultant Pathologist & the Associate
              Director at Dr. Doda’s Diagnostics & Healthcare in Delhi. She did
              her MD from Himalayan Institute of Medical Sciences, Dehradun in
              2017 and Senior Residency at Dr. RML Hospital, New Delhi...
            </p>
            <Link
              to="/doctor-ananya-doda-sarin-details"
              className="explore_more"
            >
              Explore More <i className="icofont-rounded-double-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurDoctors;
