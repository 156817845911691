import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const FacilitiesBox = () => {
  return (
    <React.Fragment>
      {/* <div className="nk__waves top">
        <img
          src="/images/purple-wave-desktop-top.svg"
          alt="purple-wave-desktop-top"
        />
      </div> */}

      <div className="location-area ptb-70 our-vision nk_facilities_page_container">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Facilities</h2>
            </ScrollAnimation>
          </div>

          <div className="row location-wrap">
            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/radiology">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/radiology-icon.png"
                      alt="radiology-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>Radiology</h3>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/laboratories">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/laboratory-icon.png"
                      alt="laboratory-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>Laboratories</h3>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/fetal-medicine">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/fetal-icon.png"
                      alt="fetal-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>Fetal Medicine</h3>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/liver-fibroscan">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/liver-fibroscan-icon.png"
                      alt="pulmonology-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>FibroScan</h3>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/cardiology">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/cardiology-icon.png"
                      alt="cardiology-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>Cardiology</h3>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/molecular-diagnostics">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/genetics-icon.png"
                      alt="genetics-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>Molecular Diagnostics & Genetics</h3>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/neurology">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/neurology-icon.png"
                      alt="neurology-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>Neurology</h3>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <Link to="/pulmonology">
                <div className="location-item">
                  <p>
                    <img
                      src="/images/pulmonology-icon.png"
                      alt="pulmonology-icon"
                      className="img-fluid"
                    />
                  </p>
                  <h3>Pulmonology</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="nk__waves bottom">
        <img
          src="/images/purple-wave-desktop-bottom.svg"
          alt="purple-wave-desktop-bottom"
        />
      </div> */}
    </React.Fragment>
  );
};

export default FacilitiesBox;
