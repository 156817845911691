import React from "react";

const DoctorDodaDetailsPage = () => {
  return (
    <React.Fragment>
      <div className="nk__waves top">
        <img
          src="/images/purple-wave-desktop-top.svg"
          alt="purple-wave-desktop-top"
        />
      </div>
      <div className="doctor-details-area ptb-35">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="doctor-details-item doctor-details-left">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-12">
                    <img src="/images/dr-ss-doda.png" alt="Doctor" />
                  </div>
                  <div className="col-md-6 col-lg-12">
                    <div className="doctor-details-contact">
                      <h3>Contact info</h3>
                      <ul>
                        <li>
                          <i className="icofont-ui-call"></i>
                          <a href="tel:+919643106759">Call: +91 9643106759</a>
                        </li>
                        <li>
                          <i className="icofont-ui-message"></i>
                          <a href="mailto:dodahealthcare@gmail.com">
                            dodahealthcare@gmail.com
                          </a>
                        </li>
                        <li>
                          <i className="icofont-location-pin"></i>
                          23-B, Pusa Road, Opposite Metro Pillar 115, New Delhi
                          - 110005, India.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div className="doctor-details-work">
                  <h3>Working hours</h3>
                  <div className="appointment-item-two-right">
                    <div className="appointment-item-content">
                      <ul>
                        <li>
                          Monday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Tuesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Wednesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Sunday <span>9:00 AM - 8:00 PM</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-lg-7">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>DR. S. S. DODA</h3>
                    <p className="nk__title">
                      <strong>FOUNDER DIRECTOR & CHIEF RADIOLOGIST</strong>
                    </p>
                  </div>

                  <div className="doctor-details-biography">
                    <p>
                      Dr. S. S. Doda, an Eminent Radiologist did his MD in
                      Radio-Diagnosis in 1981 from S. P. Medical College,
                      Bikaner, Rajasthan. Following Senior Residency at Dr. RML
                      Hospital, New Delhi, he started his career with the first
                      3rd generation whole body CT scan set up of the country at
                      Sir Ganga Ram Hospital, New Delhi in 1983, a centre where
                      patients from whole of North, Central, East and North East
                      India were referred for CT Scan. Dr Doda a pioneer in the
                      field of body CT ,delivered over 200 guest lectures on CT
                      at national and regional conferences of various branches
                      of medicine, addressed CME’s in different cities and towns
                      of India, medical colleges and state IMA meetings, etc.
                      providing continuing medical education to radiologists,
                      physicians and surgeons.
                    </p>

                    <p>
                      Towards the end of 1987, he established his own diagnostic
                      centre at Pusa Road, New Delhi. Simultaneously, he joined
                      as a Senior Consultant at Diwan Chand Aggarwal Imaging
                      Research Centre.
                    </p>

                    <p>
                      During this period, he did fellowships at world renowned
                      St. Bartholomew`s Hospital, London; Mallinckrodt
                      Institute, Washington University, St. Louis, and Thomas
                      Jefferson University, Philadelphia, USA.
                    </p>

                    <p>
                      Dr. Doda is widely travelled in India & overseas. He has
                      delivered innumerable lectures, organized several CME`s
                      conducted workshops and participated in symposia on
                      various aspects of Ultrasound, CT and MRI. He has been a
                      Guide for DNB & has a number of publications in National &
                      International Journals.
                    </p>

                    <p>
                      Dr. Doda has held several prestigious positions, most
                      distinct being President-Indian Radiological & Imaging
                      Association, 2006, Chairman, IFUMB, Delhi Chapter,
                      2006-07. He has been closely associated with International
                      organizations like Radiological Society of North America
                      (RSNA), European Society of Radiology (ESR), Asia-Oceanic
                      Society of Radiology (AOSR), Chinese, Japanese & Iranian
                      Radiological National Societies. He has also been a Member
                      of International Affairs Committee of ESR and conducted
                      the `ESR meets India` Symposium at European Congress of
                      Radiology, Vienna in 2008.
                    </p>

                    <p>
                      He has several orations & awards to his credit, the most
                      notable being" Sir J. C. Bose Memorial Oration 1999-the
                      highest academic award in Indian Radiology.
                    </p>

                    <p>
                      Dr. Doda has been appointed as the Honorary Radiologist to
                      the President of India and also is a visiting consultant
                      to the President Estate Clinic.
                    </p>

                    <p>
                      Dr. Doda apart from his exceptional career and deep
                      knowledge of his subject, is an entrepreneur with human
                      approach and provides personal touch for his patients The
                      social service rendered by him for patients who cannot
                      afford investigations is exemplary.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk__waves bottom">
        <img
          src="/images/purple-wave-desktop-bottom.svg"
          alt="purple-wave-desktop-bottom"
        />
      </div>
    </React.Fragment>
  );
};

export default DoctorDodaDetailsPage;
