import React from "react";

const RuhaniDodaKheraDetailsPage = () => {
  return (
    <React.Fragment>
      <div className="nk__waves top">
        <img
          src="/images/purple-wave-desktop-top.svg"
          alt="purple-wave-desktop-top"
        />
      </div>
      <div className="doctor-details-area ptb-35">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="doctor-details-item doctor-details-left">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-12">
                    <img src="/images/dr-ruhani-doda.png" alt="Doctor" />
                  </div>
                  <div className="col-md-6 col-lg-12">
                    <div className="doctor-details-contact">
                      <h3>Contact info</h3>
                      <ul>
                        <li>
                          <i className="icofont-ui-call"></i>
                          <a href="tel:+919643106759">Call: +91 9643106759</a>
                        </li>
                        <li>
                          <i className="icofont-ui-message"></i>
                          <a href="mailto:dodahealthcare@gmail.com">
                            dodahealthcare@gmail.com
                          </a>
                        </li>
                        <li>
                          <i className="icofont-location-pin"></i>
                          23-B, Pusa Road, Opposite Metro Pillar 115, New Delhi
                          - 110005, India.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div className="doctor-details-work">
                  <h3>Working hours</h3>
                  <div className="appointment-item-two-right">
                    <div className="appointment-item-content">
                      <ul>
                        <li>
                          Monday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Tuesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Wednesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Sunday <span>9:00 AM - 8:00 PM</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-lg-7">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>DR. RUHANI DODA KHERA</h3>
                    <p>ASSOCIATE DIRECTOR & CONSULTANT RADIOLOGIST</p>
                  </div>

                  <div className="doctor-details-biography">
                    <p>
                      A Medical graduate from Chaudhary Charan Singh University,
                      Meerut, India / S.M. College followed by Post Graduate
                      Residency Training (Radio-Diagnosis), National Board of
                      Examinations from Sir Ganga Ram Hospital, in 2012. Her
                      major work has been Comparative Evaluation of Clinical
                      Examination, Mammography, High Resolution Ultrasonography
                      and 3T MRI in Breast Lesions.
                    </p>

                    <p>
                      She joined as a Radiologist with administrative
                      responsibilities at Dr Doda’s Diagnostics & Healthcare,
                      New Delhi. Set up a Bone Mineral Densitometry (BMD) and
                      Mammography for promoting health screening in women
                      followed by operationalizing the State of the Art “Cone
                      Beam CT (CBCT)” for Dental and ENT imaging. She has
                      notable achievements in strategic operations in business
                      expansion keeping pace with the technological advancements
                      in setting up a 3T MRI and 64 Detector Row CT.
                    </p>

                    <p>
                      She has also been instrumental in getting our institution
                      the highest accreditations set by the Quality Council of
                      India: National Accreditation Board for Hospitals &
                      Healthcare Providers (NABH) and National Accreditation
                      Board for Testing and Calibration Laboratories (NABL).
                    </p>

                    <p>
                      Dr. Ruhani Doda has been working remotely and
                      intermittently travelling to India for ongoing projects of
                      the organization. She moved to the United States for her
                      MBA (University of Massachusetts) and post doctoral
                      fellowship in Radiology from the prestigious Massachusetts
                      General Hospital and Harvard Medical School. Ruhani has
                      presented her work world-over and published extensively.
                      In addition, her work in artificial intelligence and
                      patient experience has garnered numerous accolades and
                      continues to be implemented in day-to-day practice. She
                      also serves as a coach for the Mass General Brigham
                      Clinical Process Improvement and Leadership Program
                      (CPIP).
                    </p>

                    <p>
                      She has several National & International Publications to
                      her credit. Her Educational Exhibit “Multimodality Imaging
                      Of Breast Lesions With Histo-pathological Correlation” was
                      awarded as the Best Exhibit during European Congress of
                      Radiology 2011.
                    </p>

                    <p>
                      Ruhani is an imaging specialist, physician administrator
                      and healthcare entrepreneur with experience in Artificial
                      Intelligence, Quality & Safety and Patient Experience
                      clubbed with global healthcare exposure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk__waves bottom">
        <img
          src="/images/purple-wave-desktop-bottom.svg"
          alt="purple-wave-desktop-bottom"
        />
      </div>
    </React.Fragment>
  );
};

export default RuhaniDodaKheraDetailsPage;
