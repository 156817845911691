import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FormRequest } from "./api/FormRequest";

const packages = [
  {
    name: "ROUTINE HEALTH PACKAGE",
    currency: "INR",
    amount: 1100,
    info: ["Over Fasting", "Daily", "Total number of tests 41"],
    imgUrl: "images/health-packages/hp-1.png",
  },
  {
    name: "BASIC BODY CARE PACKAGE",
    currency: "INR",
    amount: 2200,
    info: ["Over Fasting", "Daily", "Total number of tests 64"],
    imgUrl: "images/health-packages/hp-2.png",
  },
  {
    name: "EXECUTIVE HEALTH CARE PACKAGE",
    currency: "INR",
    amount: 3300,
    info: ["12 Hours Fasting", "Daily", "Total number of tests 66"],
    imgUrl: "images/health-packages/hp-3.png",
  },
  {
    name: "EXECUTIVE HEALTH CARE PACKAGE EXTENDED",
    currency: "INR",
    amount: 5800,
    info: ["12 Hours Fasting", "Daily", "Total number of tests 83"],
    imgUrl: "images/health-packages/hp-3.png",
  },
  {
    name: "DIABETES CARE PACKAGE",
    currency: "INR",
    amount: 3300,
    info: ["12 Hours Fasting", "Daily", "Total number of tests 65"],
    imgUrl: "images/health-packages/hp-4.png",
  },
  {
    name: "ARTHRITIS PACKAGE",
    currency: "INR",
    amount: 3300,
    info: ["12 Hours Fasting", "Daily", "Total number of tests 6"],
    imgUrl: "images/health-packages/hp-5.png",
  },
  {
    name: "ANTENATAL SCREENING",
    currency: "INR",
    amount: 5500,
    info: ["12 Hours Fasting", "Daily", "Total number of tests 43"],
    imgUrl: "images/health-packages/hp-6.png",
  },
  {
    name: "CARDIAC CARE PACKAGE",
    currency: "INR",
    amount: 5300,
    info: ["12 Hours Fasting", "Daily", "Total number of tests 70"],
    imgUrl: "images/health-packages/hp-7.png",
  },
  {
    name: "ANAEMIA PROFILE",
    currency: "INR",
    amount: 3850,
    info: ["No Fasting Required", "Daily", "Total number of tests 29"],
    imgUrl: "images/health-packages/hp-8.png",
  },
  {
    name: "MOMMY & ME",
    currency: "INR",
    amount: 21999,
    info: ["Over Night Fasting", "Daily", "Total number of tests 87"],
    imgUrl: "images/health-packages/hp-9.png",
  },
  {
    name: "BASIC PCOS PROFILE",
    currency: "INR",
    amount: 4200,
    info: ["Over Night Fasting", "Daily", "Total number of tests 5"],
    imgUrl: "images/health-packages/hp-10.png",
  },
  {
    name: "EXTENDED PCOS PROFILE",
    currency: "INR",
    amount: 8800,
    info: ["Over Night Fasting", "Daily", "Total number of tests 12"],
    imgUrl: "images/health-packages/hp-10.png",
  },
  {
    name: "WELL WOMEN PACKAGE",
    currency: "INR",
    amount: 2700,
    info: ["Over Night Fasting", "Daily", "Total number of tests 62"],
    imgUrl: "images/health-packages/hp-11.png",
  },
  {
    name: "MENOPAUSE PACKAGE",
    currency: "INR",
    amount: 3600,
    info: ["12 Hours Fasting", "Daily", "Total number of tests 62"],
    imgUrl: "images/health-packages/hp-12.png",
  },
  {
    name: "BASIC COVID PANEL",
    currency: "INR",
    amount: 2500,
    info: ["No Fasting Required", "Daily", "Total number of tests 19"],
    imgUrl: "images/health-packages/hp-13.png",
  },
  {
    name: "IL-6 MONITORING COVID PANEL",
    currency: "INR",
    amount: 4000,
    info: ["No Fasting Required", "Daily", "Total number of tests 3"],
    imgUrl: "images/health-packages/hp-14.png",
  },
  {
    name: "ADVANCED COVID PANEL",
    currency: "INR",
    amount: 9500,
    info: ["No Fasting Required", "Daily", "Total number of tests 42"],
    imgUrl: "images/health-packages/hp-15.png",
  },
  {
    name: "EXTENDED COVID PANEL",
    currency: "INR",
    amount: 10500,
    info: ["No Fasting Required", "Daily", "Total number of tests 43"],
    imgUrl: "images/health-packages/hp-16.png",
  },
];

const HealthPackagesPage = () => {
  return (
    <React.Fragment>
      <div className="health-package-area health-package-page ptb-70">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Health Packages</h2>
            </ScrollAnimation>
          </div>

          <div className="row">
            {packages &&
              packages.length > 0 &&
              packages.map((el) => {
                return (
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-15">
                    <div className="hp-item">
                      <div className="health-package">
                        <div className="hp-content">
                          <h3>
                            <img src={el.imgUrl} alt="" className="img-fluid" />
                            {el.name}
                          </h3>
                          <p>
                            <i className="icofont-info-circle"></i>
                            {el.info[0]}
                          </p>
                          <p>
                            <i className="icofont-ui-calendar"></i>
                            {el.info[1]}
                          </p>
                          <p>
                            <i className="icofont-settings-alt"></i>
                            {el.info[2]}
                          </p>
                        </div>
                        <div className="hp-price">
                          <div className="row align-items-center">
                            <div className="col-6">
                              <span className="discounted_price">
                                Rs. {el.amount}/-
                              </span>
                            </div>
                            <div className="col-6 nk__text-right">
                              <a
                                href="https://wa.me/919643106760"
                                target="_blank"
                                className="book__now"
                                rel="noreferrer"
                              >
                                Book Now
                              </a>
                              {/* <FormRequest
                                name={el.name}
                                amount={el.amount}
                                currency={el.currency}
                                m_id="2484180"
                                r_url="https://www.server-host.dodahealthcare.com/ccavResponseHandler"
                                c_url="https://www.server-host.dodahealthcare.com/ccavResponseHandler"
                                // r_url="http://localhost:3001/ccavResponseHandler"
                                // c_url="http://localhost:3001/ccavResponseHandler"
                              /> */}
                              {/* <button
                                className="book__now"
                                data-currency={el.currency}
                                data-amount={el.amount}
                                onClick={packageHandler}
                              >
                                Book Now
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HealthPackagesPage;
